var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outer-box vertical-center-transform" }, [
    _c("div", { staticClass: "posRel" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 login-box" },
        [
          _vm._m(0),
          !_vm.otpSent
            ? _c("phone-number-form", {
                attrs: { busy: _vm.busy, errorMessage: _vm.errorMessage },
                on: { submit: _vm.verifyPhoneNumber }
              })
            : _vm._e(),
          _vm.otpSent && !_vm.otpVerified
            ? _c("two-step", {
                attrs: {
                  busy: _vm.busy,
                  user: _vm.user,
                  errorMessage: _vm.errorMessage
                },
                on: {
                  submit: _vm.verifyOtp,
                  authenticateUserAgain: _vm.verifyOtp
                }
              })
            : _vm._e(),
          _vm.otpSent && _vm.otpVerified && _vm.deviceType == "unknown"
            ? _c("div", { staticClass: "row" }, [
                _c("h4", { staticStyle: { padding: "5%" } }, [
                  _vm._v(
                    "Download App for your mobile device from these links!"
                  )
                ]),
                _vm._m(1),
                _vm._m(2)
              ])
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 text-center" }, [
      _c("img", {
        staticClass: "pickl-logo",
        attrs: { src: "/img/pickl-logo.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6 col-sm-6" }, [
      _c(
        "a",
        {
          staticClass: "w-inline-block w-clearfix",
          attrs: {
            href:
              "https://apps.apple.com/app/apple-store/id1448015581?pt=119141788&ct=web&mt=8"
          }
        },
        [
          _c("img", {
            staticClass: "img-responsive",
            attrs: {
              src:
                "https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e560cff95c8f322132fe_Button%20App%20Store%20-%20400x120.png",
              loading: "lazy",
              alt: "Download the PICKL app in the Apple App Store"
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-6 col-sm-6" }, [
      _c(
        "a",
        {
          staticClass: "w-inline-block",
          attrs: {
            href:
              "https://play.google.com/store/apps/details?id=com.pickl.app&hl=en_US&gl=US"
          }
        },
        [
          _c("img", {
            staticClass: "img-responsive",
            attrs: {
              src:
                "https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e44bf4f151762ad31f0e_Button%20PICKL%20on%20Google%20Play%20button.png",
              loading: "lazy",
              alt: "Download the PICKL app in the Google Play Store"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }