<template>
    <div>
        <h3 class="register-header">You are invited to join  <span class="green-text">PICKL</span><br/><small>Get $5 per task! Takes 5 minutes + instant cashout.</small></h3>

        <label class="error-msg" v-html="errorMessage"></label>
        <form action="#" v-on:submit.prevent="onSubmit">
            <select v-model="user.iso_code" class="country-code-field">
              <option v-for="country in countries" :value="country.iso_code" :key="country.iso_code">{{country.name}}({{ country.isd_code }})</option>
            </select>
            <the-mask class="phone-field" mask="### ###-####" type="tel" autocomplete="mobile" placeholder="Mobile Number" v-model="user.mobile_number" name="mobile" v-validate="{ required: true, regex: /^\d{3} ?\d{3}-?\d{4}$/ }" :class="{'input': true, 'is-danger': errors.has('add-brand.mobile') }"></the-mask>
            <input :disabled="busy" type="submit" value="CONTINUE" class="btn-lg-green" />
        </form>
    </div>
</template>
<script>
import { TheMask } from 'vue-the-mask'
export default {
  name: 'PhoneNumberForm',
  props: {
    errorMessage: String,
    busy: Boolean
  },
  components: {
    TheMask
  },
  data () {
    return {
      countries: [],
      user: {
        mobile_number: '',
        iso_code: 'US',
        country_code: '+1'
      }
    }
  },
  methods: {
    onSubmit: function () {
      this.countries.forEach(country => {
        if (country.iso_code === this.user.iso_code) {
          this.user.country_code = country.isd_code
        }
      })
      this.$emit('submit', this.user)
    },
    getCountries () {
      this.$http
        .get('countries?service_enabled=true')
        .then(
          (response) => {
            this.countries = response.body.data
          },
          (response) => {
          }
        )
    }
  },
  mounted () {
    this.getCountries()
  }
}
</script>

<style scoped>
h2 {
  font-size: 51px;
  line-height: 72px;
  margin-bottom: 20px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
}

.country-code-field {
  width:30%;
}

.phone-field {
    width:65%;
}
.phone-field {
  float: right;
}
.country-code-field {
  float: left;
  height: 50px;
  border: solid 1px;
  border-radius: 4px;
}
.register-header {
  text-align: center;
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
input:disabled {
  background: gainsboro;
}
</style>
