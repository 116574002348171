<template>
    <div class="outer-box vertical-center-transform">
        <div class="posRel">
          <div class="col-xs-12 login-box">
            <div class="col-xs-12 text-center">
                <img class="pickl-logo" src="/img/pickl-logo.png" />
            </div>
            <phone-number-form :busy="busy" v-if="!otpSent"  @submit="verifyPhoneNumber" :errorMessage="errorMessage"/>
            <two-step :busy="busy" v-if="otpSent && !otpVerified" @submit="verifyOtp" @authenticateUserAgain="verifyOtp" :user="user"  :errorMessage="errorMessage" />
            <div class="row" v-if="otpSent && otpVerified && deviceType =='unknown' ">
              <h4 style="padding:5%;">Download App for your mobile device from these links!</h4>
              <div class="col-xs-6 col-sm-6">
                <a href="https://apps.apple.com/app/apple-store/id1448015581?pt=119141788&amp;ct=web&amp;mt=8" class="w-inline-block w-clearfix">
                  <img src="https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e560cff95c8f322132fe_Button%20App%20Store%20-%20400x120.png" loading="lazy" alt="Download the PICKL app in the Apple App Store" class="img-responsive">
                </a>
              </div>
              <div class="col-xs-6 col-sm-6">
                <a href="https://play.google.com/store/apps/details?id=com.pickl.app&amp;hl=en_US&amp;gl=US" class="w-inline-block">
                  <img src="https://uploads-ssl.webflow.com/601f4cbf6a5d4cc1a3c0ccd3/6026e44bf4f151762ad31f0e_Button%20PICKL%20on%20Google%20Play%20button.png" loading="lazy" alt="Download the PICKL app in the Google Play Store" class="img-responsive">
                </a>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import PhoneNumberForm from '@/components/Referral/PhoneNumberForm.vue'
import TwoStep from '@/components/SAdmin/TwoStep.vue'
export default {
  name: 'Referral',
  components: {
    PhoneNumberForm,
    TwoStep
  },
  data: function () {
    return {
      code: this.$route.params.code,
      otpSent: false,
      otpVerified: false,
      user: {},
      errorMessage: '',
      busy: false
    }
  },
  mounted () {
  },

  computed: {
    deviceType () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera

      // windows phone
      if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone'
      }
      // Android detection
      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // iOS detection
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      return 'unknown'
    }
  },

  methods: {
    verifyPhoneNumber (data) {
      this.busy = true
      this.errorMessage = ''
      data.referralCode = this.code
      this.user = data
      this.$http
        .post('referral', data)
        .then(
          (response) => {
            this.otpSent = true
            this.busy = false
          },
          (response) => {
            this.busy = false
            this.errorMessage = response.body.message
          }
        )
    },
    verifyOtp (otp) {
      this.errorMessage = ''
      this.user.otp = otp
      this.$http
        .post('verify-referral', this.user)
        .then(
          (response) => {
            if (response.body.status === true) {
              this.otpSent = true
              this.otpVerified = true
              this.busy = false
              console.log(this.deviceType)
              if (this.deviceType === 'Android') {
                window.location.href = 'https://play.google.com/store/apps/details?id=com.pickl.app'
              }
              if (this.deviceType === 'iOS') {
                window.location.href = 'https://apps.apple.com/in/app/pickl/id1448015581'
              }
            } else {
              this.errorMessage = response.body.message
            }
          },
          (response) => {
            this.busy = false
            this.errorMessage = response.body.message
          }
        )
    }
  }
}
</script>

<style scoped>
.outer-box {
  margin: 0 auto;
  max-width: 1366px;
  width: 100%;
  background-image: url("/img/background-portrait.png");
  background-size: 50% 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.pickl-logo {
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  border-radius: 7px;
}

.login-box {
  margin: 5% auto 0;
  padding: 10px 90px 20px;
}

.video-out {
  padding-left: 0;
    left: auto;
    right: 0;
}

.video-box {
  margin: 0 auto;
  /* margin: 0 0 20px; */
  padding: 25px 0 15px;
}

.video-box h3 {
  font-weight: 700;
  text-align: center;
  font-size: 44px;
  color: black;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .login-box {
    padding: 10px 35px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .outer-box {
    background-size: 100% 100vh;
        background-repeat: repeat-y;
        position: static;
        transform: none;
  }

  .video-out {
    padding-left: 15px;
        min-height: auto;
        position: static;
        transform: none;
    }

    .video-box {
        margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .login-box {
    padding: 15px;
  }

  .video-box h3 {
    font-size: 36px;
        padding: 15px;
    }
}

@media only screen and (max-height: 660px) {
    .outer-box {
        position: static;
        transform: none;
  }
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
